export const initUtilities = () => {

    // Vertical tabs
    $(document).on('click', '.vertical-tab-link', function () {
        let tab = $(this).data('tab');
        $('.vertical-tab-link, .detail').removeClass('is-active');
        $(this).addClass('is-active');
        $('#' + tab).addClass('is-active');
    })

    $(document).on('keydown', '.vertical-tab-link', function(e) {
      if (e.key === 'Enter') {
          $(this).trigger('click');
      }
    });

    // mobile selector
    $(document).on('change', '.mobile-selector', function () {
        let tab = $(this).val();
        $('.detail').removeClass('is-active');
        $('#' + tab).addClass('is-active');
    });

    // Horizontal tabs
    $(document).on('click', '.horizontal-tab-link', function () {
        let tab = $(this).data('tab');
        $('.horizontal-tab-link, .detail').removeClass('is-active');
        $(this).addClass('is-active');
        $('#' + tab).addClass('is-active');
    })

    $(document).on('keydown', '.horizontal-tab-link', function(e) {
      if (e.key === 'Enter') {
          $(this).trigger('click');
      }
    });

    // FAQ Questions
    $(document).on('click', '.faq-question', function () {
        $(this).toggleClass('open');
        $(this).next().slideToggle(200);
    });

    $(document).on('keydown', '.faq-question', function(e) {
      if (e.key === 'Enter') {
          $(this).trigger('click');
      }
    });
}

export default initUtilities;