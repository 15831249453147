import $ from "jquery";
import "slick-carousel";

window.jQuery = window.$ = $;
export const initSliders = () => {
  // HOMEPAGE TYPE - HERO SLIDER
  const slider = $(".slider");
  slider.slick({
    infinite: false,
    speed: 600,
    delay: 6000,
    slidesToShow: 1,
    fade: true,
    lazyLoad: "ondemand",
    autoplay: false,
    draggable: false,
    swipe: false,
    swipeToSlide: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          draggable: false,
          arrows: true,
          autoplay: false,
          draggable: false,
          swipe: false,
          swipeToSlide: false,
          touchMove: false,
        },
      },
    ],
  });

  // Hide navigation on the first slide
  slider.on("afterChange", (event, slick, currentSlide) => {
    // add animations to the slide content
    const activeSlide = $(slick.$slides[currentSlide]);
    activeSlide.find(".animated-title").addClass("fade-in-translate");
    activeSlide.find(".animated-body").addClass("fade-in-translate-delay-1");
    activeSlide.find(".animated-image").addClass("fade-in-translate-delay-2");

    if (currentSlide === 0) {
      $(".slick-prev").hide();
      $(".slick-next").hide();
    } else {
      $(".slick-prev").show();
      $(".slick-next").show();
    }
  });

  // Initial check to hide navigation if the first slide is active
  if (slider.slick("slickCurrentSlide") === 0) {
    $(".slick-prev").hide();
    $(".slick-next").hide();
  }

  // slider event controls
  slider.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
    const activeSlide = $(slick.$slides[currentSlide]);
    activeSlide.find(".animated-title").removeClass("fade-in-translate");
    activeSlide.find(".animated-body").removeClass("fade-in-translate-delay-1");
    activeSlide
      .find(".animated-image")
      .removeClass("fade-in-translate-delay-2");

    $(".slide-button").removeClass("slider-btn-active");
    $("#slide-button-" + nextSlide).addClass("slider-btn-active");
  });

  //navigation slide controls
  $(document).on("click", ".navigation-image", function (e) {
    slider.slick("slickGoTo", $(this).data("slide-index"));
    $("#navigation-image-" + $(this).data("slide-index")).addClass(
      "slider-btn-active"
    );
  });

    $(document).on('keydown', '.navigation-image', function(e) {
      if (e.key === 'Enter') {
          $(this).trigger('click');
      }
    });

  // on click close icon return to the first slide
  $(document).on("click", ".close-icon", function() {
    slider.slick("slickGoTo", 0);
  });

  // handle Esc key to trigger click on .close-icon
  $(document).on("keydown", function (e) {
    if (e.key === 'Escape') {
      $(".close-icon").trigger('click');
    }
  });


  // slider btn controls
  $(document).on("click", ".slide-button", function (e) {
    $(".slide-button").removeClass("slider-btn-active");
    slider.slick("slickGoTo", $(this).data("slide-index"));
    $("#slide-button-" + $(this).data("slide-index")).addClass(
      "slider-btn-active"
    );
  });

  //slider mobile controls
  $("#carousel-select").on("change", function (e) {
    slider.slick("slickGoTo", $(this).val());
  });

  // LOGO SLIDER
  $(".logo-slider").slick({
    infinite: true,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
    draggable: false,
    swipe: false,
    swipeToSlide: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          draggable: false,
          swipe: false,
          autoplay: true,
          draggable: false,
          swipeToSlide: false,
          touchMove: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          draggable: false,
          swipe: false,
          swipeToSlide: false,
          touchMove: false,
        },
      },
    ],
  });

  // ARTICLE SLIDER
  if ($(window).width() < 1180) {
    $(".article-slider").slick({
      settings: "unslick",
      responsive: [
        {
          breakpoint: 1180,
          settings: {
            draggable: false,
            autoplay: false,
            swipe: false,
            swipeToSlide: false,
            touchMove: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: false,
          },
        },
      ],
    });
  }
};
export default initSliders;
